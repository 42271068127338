<template>
    <!-- <div v-if="this.$connectivity"> -->
    <main>
        <HeaderTab :title="$t('horse.titre.creer')" />
        <div id="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="box">
                            <form @submit.prevent="validForm">

                                <!-- Nom -->
                                <div class="form-group">
                                    <label>{{ $t('horse.form.nom') }}<sup>*</sup></label>

                                    <div class="input-group">
                                        <input class="form-control" type="text" :placeholder="$t('horse.form.nom')" v-model="horse_nom" id="horse_nom" @keyup="checkHorseName" :class="{ 'is-invalid': error_name }" required>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <!-- Sexe -->
                                        <div class="form-group">
                                            <label>{{ $t('horse.form.sexe') }}<sup>*</sup></label>
											<e-select
												v-model="horse_sexe"
												id="sexe_code"
												track-by="sexe_code"
												label="sexe_label"
												:placeholder="$t('horse.placeholder.sexe')"
												:selectedLabel="$t('select.selectedLabel')"
												:options="horse_sexes"
												:searchable="true"
												:allow-empty="false"
												:loading="isLoadingHorseSexe"
												:show-labels="false"
											>
												<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- Robe -->
                                        <div class="form-group">
                                            <label>{{ $t('horse.form.robe') }}<sup>*</sup></label>
											<e-select
												v-model="horse_robe"
												id="robe_code"
												track-by="robe_code"
												label="robe_label"
												:placeholder="$t('horse.placeholder.robe')"
												:selectedLabel="$t('select.selectedLabel')"
												:options="horse_robes"
												:searchable="true"
												:allow-empty="false"
												:loading="isLoadingHorseRobe"
												:show-labels="false"
											>
												<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <!-- Race -->
                                        <div class="form-group">
                                            <label>{{ $t('horse.form.race') }}<sup>*</sup></label>
											<e-select
												v-model="horse_race"
												id="race_code"
												track-by="race_code"
												label="display_label"
												:placeholder="$t('horse.placeholder.race')"
												:selectedLabel="$t('select.selectedLabel')"
												:options="horse_races"
												:searchable="true"
												:allow-empty="false"
												:loading="isLoadingHorseRace"
												:show-labels="false"
											>
												<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
												<template slot="noOptions">{{ $t('global.list_empty') }}</template>
											</e-select>
                                        </div>
                                    </div>
                                </div>

                                <!-- Date de naissance -->
                                <div class="form-group">
                                    <label>{{ $t('horse.form.datenaissance') }}<sup>*</sup></label>
                                    <div class="input-group">
                                        <e-datepicker v-model="horse_datenaissance" />
                                        <div class="input-group-append">
                                            <span class="input-group-text"><font-awesome-icon :icon="['fal', 'calendar-alt']" /></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12 mt-4">
                                        <div class="row">
                                            <button type="button" v-on:click="OpenAddPere" class="btn btn-primary rounded-pill mr-4">{{$t('horse.ajout_pere')}}</button>
                                            <h6 v-if="horsePere.birthName != null" class="mt-3">{{$t('horse.pere') + ': '+ horsePere.birthName}}</h6>
                                        </div>
                                        <AddFoalParents 
                                            v-if="AddPere"
                                            :horse_sexe="sexeM"
                                            @update:horse="addHorsePere"
                                        />
                                    </div>
                                    <div class="col-12 mt-4">
                                        <div class="row">
                                            <button type="button" v-on:click="OpenAddMere" class="btn btn-primary rounded-pill mr-4">{{$t('horse.ajout_mere')}}</button>
                                            <h6 v-if="horseMere.birthName != null" class="mt-3 ml-6">{{$t('horse.mere') + ': '+ horseMere.birthName}}</h6>
                                        </div>
                                        <AddFoalParents 
                                            v-if="AddMere"
                                            :horse_sexe="sexeF"
                                            @update:horse="addHorseMere"
                                        />
                                    </div>
                                </div>

                                <!-- Message d'erreur si besoin -->
                                <ErrorAlert v-if="message_erreur_code !== ''" :messageI18n="message_erreur_code" />

                                <!-- Bouton de validation du formulaire -->
                                <div class="text-center mt-5">
                                    <button type="submit" v-on:click="checkForm" class="btn btn-primary rounded-pill" :disabled="error_name">
                                        {{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus-circle']"/>
                                    </button>
                                </div>
                            </form>
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
</main>
    <!-- <div v-else> -->
        <!-- <b-alert variant="danger" show>{{ $t('global.connection_necessaire') }}</b-alert> -->
    <!-- </div> -->
</template>

<script type="text/javascript">
    import { EventBus } from 'EventBus';

    import ConfigMixin from '@/mixins/Config';
    import HorseMixin from '@/mixins/Horse';
    import Tools from '@/mixins/Tools';
    import _isEmpty from 'lodash/isEmpty'
    import UserMixin from "@/mixins/User.js"

    export default {
        name: "HorseCreation",
        mixins: [ConfigMixin, HorseMixin, Tools, UserMixin],
        data () {
            return {
                horsePere: {},
                horseMere: {},
                sexeM: ['Mâle', 'Hongre'],
                sexeF: ['Femelle'],
                AddMere: false,
                AddPere: false,
                horse_races: [],
                horse_robes: [],
                horse_sexes: [],

                horse_nom: null,
                horse_race: null,
                horse_robe: null,
                horse_sexe: null,
                horse_datenaissance: null,

                required_values: [
                        'horse_nom',
                        'horse_race',
                        'horse_sexe',
                        'horse_robe',
                        'horse_datenaissance'
                    ],
				message_erreur_code: '',
				isLoadingHorseSexe: true,
				isLoadingHorseRobe: true,
				isLoadingHorseRace: true,
                addingHorse : false,
                error_name: false
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_robes = await this.getRobes()
                let horse_sexes = await this.getSexes()
                let horse_races = await this.getRaces()
                const config_sexe = this.getConfigHorseSexes()
                const config_race = this.getConfigHorseRaces()

                if(config_sexe.length > 0) {
                    horse_sexes = horse_sexes.filter(sexe => config_sexe.includes(sexe.sexe_code))
                }
                if(config_race.length > 0) {
                    horse_races = horse_races.filter(race => config_race.includes(race.race_code))
                }
                this.horse_sexes = horse_sexes
                this.horse_races = horse_races
                this.horse_races = this.horse_races.map((race) => {
                    race.display_label = this.$t("race." + race.race_code) +' / '+ race.race_label
                    return race
                })
				
				this.isLoadingHorseSexe = false
				this.isLoadingHorseRobe = false
				this.isLoadingHorseRace = false

				this.horse_robe = this.horse_robes.find(robe => robe.robe_code === 'UKN')
				this.horse_race = this.horse_races.find(race => race.race_code === 'UKN')
            },

            async validForm() {
                if(!this.addingHorse){
                    this.addingHorse = true

                    let params = {
                        horse_nom: this.horse_nom,
                        horse_sexe: this.horse_sexe.sexe_code,
                        horse_robe: this.horse_robe.robe_code,
                        horse_race: this.horse_race.race_code,
                        horse_datenaissance: this.horse_datenaissance,
                        dam: this.horseMere,
                        sire: this.horsePere
                    }
                    let retour = await this.addHorseManuel(params)
                    if(retour.retour) {
                        if(this.$route.params.from == "pax") {
                            this.$router.push({ name: 'HorsePax', params: { horse_id: retour.retour.horse_id }})
                        }
                        else {
                            this.$router.push({ name: 'horseFiche', params: { horse_id: retour.retour.horse_id }})
                        }
                    }
                    this.addingHorse= false
                }   
            },

            addHorsePere(horse) {
                this.horsePere = horse
                this.AddPere = false
            },

            addHorseMere(horse) {
                this.horseMere = horse
                this.AddMere = false
            },

            checkForm() {
                let el = ''
                let error = false

                // On vérifie chaque input obligatoire
                for(let i=0; i<this.required_values.length; i++) {
                    el = this.required_values[i]

                    // On ajoute la classe error sur les div des v-model non remplis
                    if(this[el] === null || this[el] === undefined) {
                        error = true
                    }
                }

                if(error) {
                    this.message_erreur_code = "formulaire.erreur_champs_non_remplis"
                }
            },
            OpenAddPere() {
                this.AddPere = !this.AddPere
            },
            OpenAddMere() {
                this.AddMere = !this.AddMere
            },

            checkHorseName() {
                this.error_name = false
                this.horse_nom = this.accentsTidy(this.horse_nom).toUpperCase()
                if(_isEmpty(this.horse_nom) || !/^[^!"`#%&,:;<>=@{}~$()*+/?[\]^|]+$/.test(this.horse_nom)) {
                    this.error_name = true
                }
            }
        },
        components: {
            HeaderTab: () => import('GroomyRoot/components/HeaderTab'),
            ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
            AddFoalParents: () => import('../components/Horse/AddFoalParents'),
        }
    };
</script>
